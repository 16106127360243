<template>
  <section class="achievement">
    <div class="container-full">
      <div class="achievement__wrapper">
        <div class="achievement__item">
          <div class="achievement__item-top">
            <p>{{$t('qic_achievement.heading_1')}}</p>
          </div>
          <p>{{$t('qic_achievement.desc_1')}}</p>
        </div>
        <div class="achievement__item">
          <div class="achievement__item-top">
            <p>{{$t('qic_achievement.heading_2')}} <img :src="require(`./../assets/images/main/rating1-${locale}.svg`)" alt="rating qic" loading="lazy"></p>
          </div>
          <p>{{$t('qic_achievement.desc_2')}}</p>
        </div>
        <div class="achievement__item">
          <div class="achievement__item-top achievement__item-top">
            <p class="flex-around">{{$t('qic_achievement.heading_3')}}<img src="./../assets/images/main/stars-achiev.svg" class="stars" alt="stars" loading="lazy"></p>
          </div>
          <p>{{$t('qic_achievement.desc_3')}} <a v-scroll-to="'#feedback'" data-title="click_review" @click.prevent="trackClick">{{$t('qic_achievement.desc_5')}}</a></p>
        </div>
        <div class="achievement__item achievement__item-qic">
          <div class="achievement__item-top">
            <p><span>{{$t('qic_achievement.heading_4')}} <i>{{$t('qic_achievement.heading_4_2')}}</i></span> <img src="./../assets/images/main/rating2.svg" alt="rating qic" loading="lazy"></p>
          </div>
          <p><a href="https://qic-group.com/" target="_blank" data-title="to_visit_qic_group" @click.prevent="trackClick">{{$t('qic_achievement.desc_4')}}</a></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  name: 'LandingAchievement',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    trackClick (event) {
      window.gtag('event', event.target.dataset.title, {
        Label: 'main_page'
      })
      if (event.target.href) {
        window.open(event.target.href, event.target.target || '_self')
      }
    }
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    if (window.matchMedia('(min-width: 991px)').matches) {
      const achievement = gsap.timeline({
        scrollTrigger: {
          trigger: '.achievement'
        }
      })
      achievement.from('.achievement', { opacity: 0, y: 40, delay: 0.3 })
    }
  }
}
</script>
