<template>
  <section class="insure">
    <div class="container-full">
      <h3>We Can Insure Your Belongings. <span>Or a Skyscraper</span></h3>
    </div>
    <div class="insure__wrapper">
      <div class="insure__item">
        <img src="./../assets/images/main/slider-big.png" loading="lazy" alt="">
        <div class="insure__item-list">
          <p>Your <a href="#">Travel Bag</a></p>
          <p>Your <a href="#">Home Property</a></p>
          <p>Your <a href="#">Car & Motorcycle</a></p>
          <p>Your <a href="#">Boat</a></p>
          <p>Wembley Stadium</p>
        </div>
      </div>
    </div>
    <a href="#" class="btn">Get Your Insurance Now</a>
  </section>
</template>

<script>
export default {
  name: 'LandingInsure',
  computed: {
    locale () {
      return this.$i18n.locale
    }
  }
}
</script>
