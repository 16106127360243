<template>
  <section class="qic-group">
    <div class="container-full">
      <div class="qic-group__wrapper">
        <img :src="require(`./../assets/images/main/qic-${locale}.png`)" class="qic-group__img qic-group__img_desktop" alt="QIC group" loading="lazy">
        <div class="qic-group__left">
          <h3>{{$t('qic_group.title')}}</h3>
          <p>{{$t('qic_group.text')}}</p>
          <a href="https://qic-group.com/" target="_blank" class="qic-group__btn" data-title="to_visit_qic_group" @click.prevent="trackClick">{{$t('qic_group.link_text')}} <ArrowIcon/></a>
        </div>
        <div class="qic-group__mobile">
          <div class="qic-group__mobile-left">
            <span>{{$t('qic_group.statistics_1')}}</span>
            <p>{{$t('qic_group.statistics_2')}}</p>
          </div>
          <div class="qic-group__mobile-right">
            <span>{{$t('qic_group.statistics_4')}}</span>
            <p>{{$t('qic_group.statistics_5')}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ArrowIcon from './../components/about/assets/svg/ArrowIcon'
export default {
  name: 'LandingQicGroup',
  components: {
    ArrowIcon
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    trackClick (event) {
      window.gtag('event', event.currentTarget.dataset.title, {
        Label: 'main_page'
      })
      window.open(event.currentTarget.href, event.currentTarget.target || '_self')
    }
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    if (window.matchMedia('(min-width: 991px)').matches) {
      const qicGroup = gsap.timeline({
        scrollTrigger: {
          trigger: '.qic-group'
        }
      })
      qicGroup.from('.qic-group', { opacity: 0, y: 40, delay: 0.3 })
    }
  }
}
</script>
