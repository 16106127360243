<template>
  <section class="feedback" id="feedback">
    <div class="container-full">
      <h3>{{$t('qic_feedback.heading')}}</h3>
      <div class="feedback__wrapper">
        <div class="feedback__wrapper-content">
          <div class="feedback__content">
            <div class="feedback__item feedback__item_none">
              <div class="feedback__item-ava"><UserIcon /></div>
              <div class="feedback__item-text">
                <p>{{$t('qic_feedback.feedback_text_1')}}</p>
                <span>{{$t('qic_feedback.feedback_author_1')}}</span>
              </div>
            </div>
            <div class="feedback__item feedback__item_none">
              <div class="feedback__item-ava green"><UserIcon /></div>
              <div class="feedback__item-text">
                <p>{{$t('qic_feedback.feedback_text_2')}}</p>
                <span>{{$t('qic_feedback.feedback_author_2')}}</span>
              </div>
            </div>
            <div class="feedback__item feedback__item_none">
              <div class="feedback__item-ava blue"><UserIcon /></div>
              <div class="feedback__item-text">
                <p>{{$t('qic_feedback.feedback_text_3')}}</p>
                <span>{{$t('qic_feedback.feedback_author_3')}}</span>
              </div>
            </div>
            <div class="feedback__item feedback__item_none">
              <div class="feedback__item-ava"><UserIcon /></div>
              <div class="feedback__item-text">
                <p>{{$t('qic_feedback.feedback_text_4')}}</p>
                <span>{{$t('qic_feedback.feedback_author_4')}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="feedback__rating">
          <div class="feedback__rating-content">
            <div class="feedback__rating-top">
              <p>4.7</p>
              <img src="./../assets/images/main/stars.svg" alt="" loading="lazy">
            </div>
            <span>{{$t('qic_feedback.feedback_rating')}}</span>
          </div>
          <div class="feedback__rating-share">
            <p>{{$t('qic_feedback.share')}}</p>
            <div class="feedback__rating-social">
              <a href="https://instagram.com/qic_online" target="_blank" data-title="to_instagram" @click.prevent="trackClick"><img src="./../assets/images/main/inst.svg" alt="" loading="lazy"></a>
              <a href="http://facebook.com/qic.online" target="_blank" data-title="to_facebook" @click.prevent="trackClick"><img src="./../assets/images/main/fb.svg" alt="" loading="lazy"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserIcon from './icons/UserIcon.vue'
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// import { CustomEase } from 'gsap/CustomEase'

export default {
  name: 'LandingFeedback',
  components: {
    UserIcon
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    trackClick (event) {
      window.gtag('event', event.currentTarget.dataset.title, {
        Label: 'main_page'
      })
      window.open(event.currentTarget.href, event.currentTarget.target || '_self')
    }
  },
  mounted () {
    // gsap.registerPlugin(ScrollTrigger, CustomEase)
    // if (window.matchMedia('(min-width: 992px)').matches) {
    //   const feedbackItems = gsap.timeline({
    //     scrollTrigger: {
    //       trigger: '.feedback'
    //     }
    //   })
    //   feedbackItems.from('.feedback__item_none', { display: 'none', scale: 0, x: '-50%', stagger: 2, duration: 0.9, opacity: 0, ease: CustomEase.create('custom', 'M0,0 C0.14,0 0.719,0.981 0.726,0.998 0.788,0.914 0.84,0.936 0.859,0.95 0.878,0.964 0.897,0.985 0.911,0.998 0.922,0.994 0.939,0.984 0.954,0.984 0.969,0.984 1,1 1,1  ') })
    //     .to('.feedback__item-text_loader i', { opacity: 0, width: 0, height: 0 })
    //     .to('.feedback__item_loader', { duration: 0.2, scale: 0, x: '-55%', display: 'none', opacity: 0, ease: 'linear' })
    // }
  }
}
</script>
