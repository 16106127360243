<template>
  <p class="privpolicy" :class="{'privpolicy--offset': withOffset}">
    {{ $t('callback_popup.privacypolicy.text_1') }}
    <a :href="$options.privacyPolicy" target="_blank">
      {{ $t('callback_popup.privacypolicy.text_2') }}
    </a>
  </p>
</template>

<script>
export default {
  props: {
    withOffset: {
      type: Boolean,
      default: false
    }
  },
  privacyPolicy: '/pdf/QIC_Data_Privacy_Notice.pdf'
}
</script>

<style lang="scss" scoped>
.privpolicy {
  color: #727277;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  &--offset {
    margin-top: 20px;
  }
  a {
    color: #5a2cf2;
  }
}
</style>
