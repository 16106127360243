<template>
  <div class="contacts__map-item">
    <div class="contacts__map-head">
      <h5>{{ title }}</h5>
      <i><img src="./../assets/images/close.svg" alt=""  @click.prevent="onClose"></i>
    </div>
    <div class="contacts__map-address">
      <p v-if="address">{{address}}</p>
      <p v-if="phone">{{$t('contacts_contact_number', { phone: phone })}}</p>
    </div>
    <div class="contacts__map-time">
      <p v-if="schedule" v-html="schedule"></p>
      <div class="contacts__branches-ramadan" v-if="ramadanSchedule">
          <p v-if="titleAddSchedule" v-html="titleAddSchedule"></p>
          <p v-html="ramadanSchedule"></p>
        </div>
    </div>
    <a :href="mapHref" target="_blank" class="btn btn-second btn-second_medium"> {{$t('contacts_show_on_google_maps')}} <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2071 4.29289C12.8166 3.90237 12.1834 3.90237 11.7929 4.29289C11.4024 4.68342 11.4024 5.31658 11.7929 5.70711L17.0858 11H5.5C4.94772 11 4.5 11.4477 4.5 12C4.5 12.5523 4.94772 13 5.5 13H17.0858L11.7929 18.2929C11.4024 18.6834 11.4024 19.3166 11.7929 19.7071C12.1834 20.0976 12.8166 20.0976 13.2071 19.7071L20.2071 12.7071C20.5976 12.3166 20.5976 11.6834 20.2071 11.2929L13.2071 4.29289Z" fill="#5927FF"/>
    </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MapBranchPopup',
  props: {
    branchInfo: {},
    mapHref: { type: String, default: '#' }
  },
  emits: [
    'onShowLocation',
    'onClose'
  ],
  computed: {
    title () {
      if (!this.branchInfo) return ''
      return this.branchInfo?.name
    },
    address () {
      if (!this.branchInfo) return ''
      return this.branchInfo?.address
    },
    phone () {
      if (!this.branchInfo && !this.branchInfo?.phone) return ''
      return '+974' + this.branchInfo?.phone
    },
    schedule () {
      if (!this.branchInfo && !this.branchInfo?.schedule) return ''
      return this.branchInfo?.schedule
    },
    ramadanSchedule () {
      if (!this.branchInfo && !this.branchInfo?.ramadanSchedule) return ''
      return this.branchInfo?.ramadanSchedule
    },
    titleAddSchedule () {
      if (!this.branchInfo && !this.branchInfo?.titleAddSchedule) return ''
      return this.branchInfo?.titleAddSchedule
    }
  },
  methods: {
    onShowLocation () {
      this.$emit('onShowLocation')
    },
    onClose () {
      this.$emit('onClose')
    }
  }
}
</script>
