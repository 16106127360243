<template>
  <section class="offer" :class="{'offer_ar': locale === 'ar'}" id="offer">
    <div class="container-full">
      <h1 class="offer__title">{{$t('qic_main_title')}}</h1>

      <div class="offer__apps">
        <div class="offer__top">
          <a v-keep-query class="offer__item-top car" :href="`/${locale_}/car-insurance`" data-title="carIns" @click.prevent="catchTopProductsClick">
            <div class="offer__item-info">
              <h4>{{$t('qic_offer_apps.heading_1')}}</h4>
              <h4>{{$t('qic_offer_apps.heading_1_2')}}</h4>
              <p>{{$t('qic_offer_apps.desc_1')}}</p>
            </div>
            <div class="offer__item-btn">
              <span>{{$t('qic_offer_apps.check_price')}}</span> <i><ArrowIcon /></i>
            </div>
          </a>
          <a v-keep-query class="offer__item-top visitors" :href="`/${locale_}/visitors`" data-title="visitors" @click.prevent="catchTopProductsClick">
            <div class="offer__item-info">
              <h4>{{$t('qic_offer_apps.heading_10')}}</h4>
              <p>{{$t('qic_offer_apps.desc_10')}}</p>
            </div>
            <div class="offer__item-btn">
              <span>{{$t('qic_offer_apps.check_price')}}</span> <i><ArrowIcon /></i>
            </div>
          </a>
          <a v-keep-query class="offer__item-top travel" :href="`/${locale_}/travel-insurance`" data-title="travel" @click.prevent="catchTopProductsClick">
            <div class="offer__item-info">
              <h4>{{$t('qic_offer_apps.heading_2')}}</h4>
              <h4>{{$t('qic_offer_apps.heading_9')}}</h4>
              <p>{{$t('qic_offer_apps.desc_2')}}</p>
            </div>
            <div class="offer__item-btn">
              <span>{{$t('qic_offer_apps.check_price')}}</span> <i><ArrowIcon /></i>
            </div>
          </a>
        </div>
        <div class="offer__bottom">
          <a v-keep-query class="offer__item-bottom pab" :href="`/${locale_}/personal-accident-insurance`" data-title="personal_accident">
            <div class="offer__item-img">
            </div>
            <h4>{{$t('qic_offer_apps.heading_4')}}</h4>
          </a>
          <a v-keep-query class="offer__item-bottom home" :href="`/${locale_}/home-insurance`" data-title="home_ins">
            <div class="offer__item-img">
            </div>
            <h4>{{$t('qic_offer_apps.heading_3')}}</h4>
          </a>
          <a v-keep-query class="offer__item-bottom moto" :href="`/${locale_}/motorcycle-insurance`" data-title="motorcycle">
            <div class="offer__item-img">
            </div>
            <h4>{{$t('qic_offer_apps.heading_5')}}</h4>
          </a>
          <a v-keep-query class="offer__item-bottom boat" :href="`/${locale_}/boat-yacht-insurance`" data-title="boat_yacht">
            <div class="offer__item-img">
            </div>
            <h4>{{$t('qic_offer_apps.heading_6')}}</h4>
          </a>
<!--          <a class="offer__item-bottom visitors" :href="`/${locale_}/visitors`">
            <div class="offer__item-img">
            </div>
            <h4>{{$t('qic_offer_apps.heading_10')}}</h4>
          </a>-->
          <a v-keep-query class="offer__item-bottom golf" href="https://www.qic-insured.com/Online/GolfInsurance.do" target="_blank" data-title="golf">
            <div class="offer__item-img">
            </div>
            <h4>{{$t('qic_offer_apps.heading_7')}}</h4>
          </a>
          <a v-keep-query class="offer__item-bottom business" :href="`/${locale_}/business-shield`" data-title="bussiness_shield">
            <div class="offer__item-img">
            </div>
            <h4>{{$t('qic_offer_apps.heading_8')}}</h4>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ArrowIcon from './icons/ArrowIcon.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  name: 'LandingOffer',
  components: {
    ArrowIcon
  },
  props: {
    locale: { type: String }
  },
  computed: {
    locale_ () {
      if (this.locale) return this.locale
      return 'en'
    }
  },
  methods: {
    catchTopProductsClick (event) {
      this.trackBuyPolicyClick(event.currentTarget.dataset.title)
      window.open(event.currentTarget.href, event.currentTarget.target || '_self')
    },
    trackBuyPolicyClick (product) {
      window.gtag('event', 'click_buy_policy', {
        Label: 'main_page',
        Product: product
      })
    }
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    if (window.matchMedia('(min-width: 991px)').matches) {
      const findProvider = gsap.timeline({
        scrollTrigger: {
          trigger: '.offer'
        }
      })
      findProvider.from('.offer', { opacity: 0, y: 40, delay: 0.3 })
    }
    document.querySelectorAll('.offer__item-bottom').forEach(el => {
      el.addEventListener('click', (event) => {
        event.preventDefault()
        this.trackBuyPolicyClick(event.currentTarget.dataset.title)
        window.open(event.currentTarget.href, event.currentTarget.target || '_self')
      })
    })
  }
}
</script>
