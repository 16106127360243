<template>
  <section class="feature" ref="feature">
    <div class="container-full">
      <div class="feature__slider">
        <swiper ref="featureSwiper" :options="swiperOptions">
          <swiper-slide>
            <div class="feature__slider-item">
              <img :src="require(`./../assets/images/main/slide-1-${locale}.png`)" loading="lazy">
              <p>{{$t('qic_feature.text_1')}}</p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="feature__slider-item">
              <img src="./../assets/images/main/slide2.png" loading="lazy" alt="slide 2">
              <p>{{$t('qic_feature.text_2')}}</p>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="feature__slider-item">
              <img src="./../assets/images/main/slide3.png" loading="lazy" alt="slide 3">
              <p>{{$t('qic_feature.text_3')}}</p>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-btn-prev"><ArrowSmall /></div>
        <div class="swiper-pagination">
        </div>
        <div class="swiper-btn-next"><ArrowSmall /></div>
      </div>

      <div class="feature__list">
        <div class="feature__list-item">
          <p>{{$t('qic_feature.text_1')}}</p>
          <img src="./../assets/images/main/slide1.png" loading="lazy" alt="slide 1">
        </div>
        <div class="feature__list-item">
          <p>{{$t('qic_feature.text_2')}}</p>
          <img src="./../assets/images/main/slide2.png" loading="lazy" alt="slide 2">
        </div>
        <div class="feature__list-item">
          <p>{{$t('qic_feature.text_3')}}</p>
          <img src="./../assets/images/main/slide3.png" loading="lazy" alt="slide 3">
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import ArrowSmall from './icons/ArrowSmall.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import awesomeSwiperBuilder from 'vue-awesome-swiper/dist/exporter'
import SwiperConstructor from 'swiper'

import 'swiper/css/swiper.css'

const { Swiper, SwiperSlide } = awesomeSwiperBuilder(SwiperConstructor)

export default {
  name: 'LandingFeature',
  components: {
    Swiper,
    SwiperSlide,
    ArrowSmall
  },
  data () {
    return {
      swiperOptions: {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        slidesPerView: 1,
        autoHeight: true,
        loop: true,
        autoplay: {
          delay: 7000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev'
        }
      }
    }
  },
  methods: {
    upValue () {
      if (ScrollTrigger.isInViewport(this.$refs.feature)) {
        this.swiper.autoplay.start()
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.featureSwiper.$swiper
    },
    locale () {
      return this.$i18n.locale
    }
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    if (window.matchMedia('(min-width: 991px)').matches) {
      const feature = gsap.timeline({
        scrollTrigger: {
          trigger: '.feature'
        }
      })
      feature.from('.feature', { opacity: 0, y: 40, delay: 0.3 })
    }
    if (window.matchMedia('(min-width: 1200px)').matches) {
      this.swiper.autoplay.stop()
      ScrollTrigger.create({
        start: 0,
        end: 'max',
        onUpdate: this.upValue
      })
    }
  }
}
</script>

<style lang="scss">
.swiper{
  padding: 0 !important;
}
.swiper-slide{
  padding: 25px 0;
  //visibility: hidden;
  transition: .3s;
  //opacity: 0;
}
.swiper-slide-active{
  //visibility: visible;
  //transition: .3s;
  opacity: 1;
}
.swiper-wrapper{
  padding: 18px 0;
}
.swiper-pagination {
  display: grid;
  gap: 8px;
  position: absolute;
  inset-inline-end: 32px;
  z-index: 1;
  padding: 16px;
  border-radius: 25px;
  top: 50%;
  background-color: #ffffff;
  transform: translate(0, -50%);
}
.swiper-btn-next,
.swiper-btn-prev {
  position: absolute;
  inset-inline-end: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  z-index: 1;
  cursor: pointer;
}
.swiper-btn-next {
  bottom: 85px;
  transform: rotate(180deg);
  @media(max-width: 1439px) {
    bottom: 65px;
  }
}
.swiper-btn-prev {
  top: 85px;
  @media(max-width: 1439px) {
    top: 65px;
  }
}
.swiper-pagination-bullet{
  background: #e1e8ec;
  width: 4px;
  height: 16px;
  display: block;
  border-radius: 5px;
  opacity: 1;
  cursor: pointer;
}
.swiper-pagination-bullet-active{
  background: #1c1c1c;
}
</style>
