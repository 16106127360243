<template>
  <section class="recognitions">
    <div class="container-full">
      <h3>{{$t('qic_awards.heading')}}</h3>
      <div class="recognitions__wrapper">
        <div
          class="recognitions__item"
          :class="`recognitions__bg_${award.code}`"
          v-for="award in awards"
          :key="award.code"
        >
          <h4>{{ award.name }}</h4>
          <p>{{ award.desc }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { mapActions } from 'vuex'
export default {
  name: 'LandingRecognitions',
  computed: {
    locale () {
      return this.$i18n.locale
    },
    awards () {
      return this.$store.state.awards
    }
  },
  methods: {
    ...mapActions([
      'getAwards'
    ])
  },
  mounted () {
    this.getAwards(0)

    gsap.registerPlugin(ScrollTrigger)
    if (window.matchMedia('(min-width: 991px)').matches) {
      const recognitions = gsap.timeline({
        scrollTrigger: {
          trigger: '.recognitions'
        }
      })
      recognitions.from('.recognitions', { opacity: 0, y: 40, delay: 0.3 })
    }
  }
}
</script>
