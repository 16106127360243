<template>
  <section class="cta">
    <div class="container-full">
      <div class="cta__wrapper">
        <CtaIcon/>
        <div class="cta__info">
          <h3>{{$t('qic_cta.heading')}}</h3>
          <p class="cta__desc">{{$t('qic_cta.desc')}}</p>
          <div class="cta__steps">
            <div class="cta__steps-item">
              <span>1</span>
              <p>{{$t('qic_cta.step_1')}}</p>
            </div>
            <div class="cta__steps-item">
              <span>2</span>
              <p>{{$t('qic_cta.step_2')}}</p>
            </div>
          </div>
          <a v-scroll-to="'#landing-main'" class="cta__btn" @click.prevent="trackClick('click_get_insurance')">{{$t('qic_cta.button')}}</a>
        </div>
        <div class="cta__img">
          <video muted="muted" autoplay="autoplay" loop="loop" preload="true">
            <source src="./../assets/video/two-minutes.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import CtaIcon from '@/components/icons/CtaIcon'

export default {
  name: 'LandingCta',
  components: { CtaIcon },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    trackClick (eventName) {
      window.gtag('event', eventName, {
        Label: 'main_page'
      })
    },
    goToForm () {
      const scrollToElementCoords = this.$refs.form.getBoundingClientRect()
      window.scrollTo({
        left: scrollToElementCoords.left + window.pageXOffset,
        top: scrollToElementCoords.top + window.pageYOffset,
        behavior: 'smooth'
      })
    }
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    if (window.matchMedia('(min-width: 991px)').matches) {
      const cta = gsap.timeline({
        scrollTrigger: {
          trigger: '.cta'
        }
      })
      cta.from('.cta', { opacity: 0, y: 40, delay: 0.3 })
    }
  }
}
</script>
